export const config = {
  BASE_URL: "https://lb-cdn-prod.beder.app/api/v1",
  MUSEMENT_BASE_URL: "https://lb-cdn-prod.beder.app/api/v1/musement",
  BEDER_APP_URL: "https://theapp.beder.app",
  HERO_SECTION_BG:
    "https://beder-sales.s3.eu-west-3.amazonaws.com/dev/styles/background/hero-section-bg.jpg",
  TRACKING_URL: "https://lb-cdn-prod.beder.app/api/v1/events/track",
  TOP_CITIES: "900009,23,100078,100146",
  SEARCH_CITIES: "29,300012,900010,100095,281,213,100026,100073,100017,212,100097,898,100116,100133,100138,1,33"
};


