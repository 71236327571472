import { Modal } from "@components/shared";
import Image from "next/image";

import handCool from "../../assets/hand_cool.png";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";

export const PLACEHOLDER_COOKIE_NAME = "show_placeholder";
export const PLACEHOLDER_SESSION_COOKIE_NAME = "show_placeholder_session";

export const Placeholder = ({
  onOpen,
  onClose,
}: {
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const { t } = useTranslation();

  const [showPlaceholder, setShowPlaceholder] = useState(false);

  useEffect(() => {
    const cookie = Cookies.get(PLACEHOLDER_COOKIE_NAME);
    if (cookie === "true") {
      if (window.location.pathname.includes("expand_activities=true")) {
        Cookies.remove(PLACEHOLDER_COOKIE_NAME);
        return;
      }

      //tutto only 1 time per session and first time only to trigger interaction in the web
      let sessionId = window.sessionStorage.getItem("SessionId")?.toString();
      const cookie_session = Cookies.get(PLACEHOLDER_SESSION_COOKIE_NAME)
      if(cookie_session == sessionId)
      {
        setShowPlaceholder(false);
        onClose && onClose();
        return;
      }
      
      Cookies.set(PLACEHOLDER_SESSION_COOKIE_NAME, sessionId!)


      setShowPlaceholder(true);
      onOpen && onOpen();
    }
    else
    {
      let sessionId = window.sessionStorage.getItem("SessionId")?.toString();
      const cookie_session = Cookies.get(PLACEHOLDER_SESSION_COOKIE_NAME)
      if(cookie_session == sessionId)
      {
        setShowPlaceholder(false);
        onClose && onClose();
        return;
      }

    }



  }, []);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    Cookies.set(PLACEHOLDER_COOKIE_NAME, "false", {
      path: "/",
    });
    setShowPlaceholder(false);
    onClose && onClose();
  };

  return (
    <Modal isOpen={showPlaceholder}>
      <div
        className="h-full w-full flex flex-col gap-6 justify-center items-center"
        onClick={handleClose}
      >
        <p className="text-white text-xl text-center font-bold w-64">
          {t("FULLSCREEN_PLACEHOLDER_TEXT")}
        </p>
        <Image src={handCool} alt="loading" width={80} height={80} />{" "}
        <button
          onClick={handleClose}
          className="bg-primary-color py-2 px-16 font-medium uppercase rounded-full"
        >
          Let&apos;s go
        </button>
      </div>
    </Modal>
  );
};
