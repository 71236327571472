import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GeneralResponse } from "../../../types/common";
import { RootState } from "../../store/store";
import { config } from "../../../shared"
import { musementCartData } from "@/types/musementCart";

export const musementCartApi = createApi({
  reducerPath: "musementCart",
  baseQuery: fetchBaseQuery({
    baseUrl: config?.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      // If we have a token set in state, let's assume that we should be passing it.
      if (state.rootReducers.authSlice.token) {
        headers.set(
          "authorization",
          `Bearer ${state.rootReducers.authSlice.token}`
        );
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getMusementCart: builder.query<GeneralResponse<musementCartData>, string>({
      query: (uuid_cart) => `/musement/get-cart?uuid_cart=${uuid_cart}`,
    })
  }),
});

export const { useGetMusementCartQuery } = musementCartApi;