import rootReducers from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import { photosApi, topPlacesApi, topExperiencesApi, spotInfoApi, globalInfoApi, MusementApi } from "../api";
import authSlice from "./auth/authSlice";
import { musementCartApi } from "@redux/api/Musement/MusementCart";

export const store = configureStore({
  reducer: {
    rootReducers,
    // Add the generated reducer as a specific top-level slice
    [photosApi.reducerPath]: photosApi.reducer,
    [topPlacesApi.reducerPath]: topPlacesApi.reducer,
    [topExperiencesApi.reducerPath]: topExperiencesApi.reducer,
    [spotInfoApi.reducerPath]: spotInfoApi.reducer,
    [globalInfoApi.reducerPath]: globalInfoApi.reducer,
    [MusementApi.reducerPath]: MusementApi.reducer,
    [musementCartApi.reducerPath]: musementCartApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(photosApi.middleware, 
      topPlacesApi.middleware,
      topExperiencesApi.middleware,
      spotInfoApi.middleware,
      globalInfoApi.middleware,
      MusementApi.middleware,
      musementCartApi.middleware,
      ),
});

export type RootState  = ReturnType<typeof store.getState>