import {createSlice} from '@reduxjs/toolkit'

interface AuthSlice {
    token : string
}

let initialState :AuthSlice = {
  token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkcWRkZDIzMjMzIiwibmFtZSI6IkJlZGVyJCQkIiwiaWF0IjoyNjM1MTI2MzJ9.zvXrAUGolkG1wwcNYKyh3-Piphe5VfKjbSeWKNWX2ZI',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, {payload} : any) {
      state.token = payload
    }
  }
})

export const { setToken } = authSlice.actions

export default authSlice.reducer