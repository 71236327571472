import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GeneralResponse } from "../../types/common";
import { IGlobalInfo } from "../../types/globalInfo";
import { RootState } from "../store/store";
import { config } from "../../shared";

export const globalInfoApi = createApi({
  reducerPath: "globalInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: config?.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      // If we have a token set in state, let's assume that we should be passing it.
      if (state.rootReducers.authSlice.token) {
        headers.set(
          "authorization",
          `Bearer ${state.rootReducers.authSlice.token}`
        );
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getGlobalInfo: builder.query<GeneralResponse<IGlobalInfo[][]>, void>({
      query: (city_id) => `/spotProfile/getGlobalInfo`,
    }),
  }),
});

export const {
  useGetGlobalInfoQuery
} = globalInfoApi;
