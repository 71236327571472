import React from "react";

interface ISpecialTextProps {
  text: string;
}

export const SpecialText = ({ text }: ISpecialTextProps) => {
  const [normalPart, coloredPart] = text.split(" ");

  return (
    <>
      {normalPart} <span className="text-teal-300">{coloredPart}</span>
    </>
  );
};
