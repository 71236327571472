import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GeneralResponse } from "../../types/common";
import { ITopExperiences } from "../../types/topExperiences";
import { RootState } from "../store/store";
import { config } from "../../shared"
import { TopExperiencesData } from "@/types/top-experiences";

export const topExperiencesApi = createApi({
  reducerPath: "topExp",
  baseQuery: fetchBaseQuery({
    baseUrl: config?.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      // If we have a token set in state, let's assume that we should be passing it.
      if (state.rootReducers.authSlice.token) {
        headers.set(
          "authorization",
          `Bearer ${state.rootReducers.authSlice.token}`
        );
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getTopExperiences: builder.query<GeneralResponse<TopExperiencesData[][]>, void>({
      query: () => "/spotProfile/getTopExperiences",
    })
  }),
});

export const { useGetTopExperiencesQuery } = topExperiencesApi;