import React from "react";

interface IHeadline2Props {
  text: string;
}

export const Headline2 = ({ text }: IHeadline2Props) => {
  const [normalPart, ...rest] = text.split(" ");
  return (
    <h2 className="text-2xl font-bold tracking-wider text-white">
      {normalPart} <span className="text-primary-color">{rest}</span>
    </h2>
  );
};
