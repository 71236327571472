import React, { useEffect } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  children?: React.ReactNode;
  isOpen: boolean;
}

export const Modal = ({ children, isOpen }: ModalProps) => {
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return isOpen
    ? createPortal(
        <div className="fixed top-0 z-[999] flex flex-col justify-center items-center w-screen h-[100dvh] bg-black/80">
          {children}
        </div>,
        document.body
      )
    : null;
};
