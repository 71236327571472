import { useEffect } from "react";
import type { AppProps } from "next/app";
import Script from "next/script";
import { useRouter } from "next/router";
import { appWithTranslation, useTranslation } from "next-i18next";
import { Provider } from "react-redux";
import Cookies from "js-cookie";

import { store } from "@redux/store/store";
import { PLACEHOLDER_COOKIE_NAME } from "@components/fullScreenVideo/Placeholder";
import languageDetector from "@lib/languageDetector";
import { events, track } from "@shared/tracking";
import i18nConfig from "../../i18n";

import "../../styles/globals.css";
import { I18nextProvider } from "react-i18next";

function MyApp({ Component, pageProps }: AppProps) {
  const { i18n } = useTranslation();
  const router = useRouter();
  useEffect(() => {
    const detectedLng = languageDetector.detect();
    if (i18n.language !== detectedLng) {
      i18n.changeLanguage(detectedLng);
      const { pathname, asPath, query } = router;
      router.replace({ pathname, query }, asPath, { locale: detectedLng });
    }

    if (
      window.location.pathname.includes("spotprofile") &&
      window.location.search.includes("experience_id") &&
      !window.location.search.includes("expand_activities=true")
    ) {
      Cookies.set(PLACEHOLDER_COOKIE_NAME, "true", {
        path: "/",
      });
    } else {
      Cookies.remove(PLACEHOLDER_COOKIE_NAME);
    }
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18nConfig}>
        <Component {...pageProps} />
      </I18nextProvider>

      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-N3JBHGJ"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>

      <Script id="google-tag-manager">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N3JBHGJ');
      `}
      </Script>
      <Script id="clarity-script" strategy="worker">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "gk76xuzy67");`}
      </Script>
      <Script
        id="google-analytics"
        strategy="worker"
        src={`https://www.googletagmanager.com/gtag/js?id=G-E5S0JWDE6K`}
      />
      <Script id="ga-script" strategy="worker">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-E5S0JWDE6K', {
            page_path: window.location.pathname,
          });
          `}
      </Script>
      {/* <Script
        id="google-adsense"
        strategy="worker"
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5061983250582180"
        crossOrigin="anonymous"
      /> */}
    </Provider>
  );
}

export default appWithTranslation(MyApp);
