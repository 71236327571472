import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GeneralResponse } from "../../types/common";

import { RootState } from "../store/store";
import { config } from "../../shared";
import {
  IDates,
  IMusementNewCart,
  ITicketOptions,
} from "../../types/musement-activity";

type tikcetOptionsParams = {
  activity_uuid: string;
  date?: string;
  uuid_pickup?: string;
  lang?: string;
};

type datesParams = {
  activity_uuid?: string;
  uuid_pickup?: string;
};

type createNewCartPayload = {
  uuid_cart: string;
  products: {
    type: string;
    product_identifier: string;
    quantity: number;
  }[];
};

export const MusementApi = createApi({
  reducerPath: "Musement",
  baseQuery: fetchBaseQuery({
    baseUrl: config?.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      if (state.rootReducers.authSlice.token) {
        headers.set(
          "authorization",
          `Bearer ${state.rootReducers.authSlice.token}`
        );
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getTicketsOptions: builder.query<
      GeneralResponse<ITicketOptions[]>,
      tikcetOptionsParams
    >({
      query: (ticketOptions) => {
        const { activity_uuid, date, lang } = ticketOptions
        // `/musement/get-ticket-options?activity_uuid=${ticketOptions.activity_uuid}&date=${ticketOptions.date}`,
        return {
          url: '/musement/get-ticket-options',
          params: ticketOptions,
        };
      }
    }),
    getDates: builder.query<GeneralResponse<IDates[]>, datesParams>({
      query: (datesParmas) =>
        `/musement/get-dates?activity_uuid=${datesParmas.activity_uuid}&uuid_pickup=${datesParmas.uuid_pickup}`,
    }),
    createNewCart: builder.mutation<GeneralResponse<IMusementNewCart>, any>({
      query: (body) => ({
        url: `/musement/new-cart`,
        method: "POST",
      }),
    }),
    addItemsToCart: builder.mutation<
      GeneralResponse<any>,
      createNewCartPayload
    >({
      query: (body) => ({
        url: `/musement/cart-item`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetTicketsOptionsQuery,
  useGetDatesQuery,
  useCreateNewCartMutation,
  useAddItemsToCartMutation,
} = MusementApi;
