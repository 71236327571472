export const DARK_THEME = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#000000",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#8b9198",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#323336",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#414954",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#2e2f31",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7a7c80",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#242427",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#202023",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#393a3f",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#202023",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#393a3f",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#202023",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#202124",
      },
    ],
  },
];

export const MAPBOX_DARK_THEME = {
  version: 8,
  name: "Basic",
  metadata: {
    "mapbox:autocomposite": true,
  },
  sources: {
    mapbox: {
      url: "mapbox://mapbox.mapbox-streets-v7",
      type: "vector",
    },
  },
  sprite: "mapbox://sprites/mapbox/basic-v8",
  glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  layers: [
    {
      id: "background",
      type: "background",
      paint: {
        "background-color": "#dedede",
      },
      interactive: true,
    },
    {
      id: "landuse_overlay_national_park",
      type: "fill",
      source: "mapbox",
      "source-layer": "landuse_overlay",
      filter: ["==", "class", "national_park"],
      paint: {
        "fill-color": "#d2edae",
        "fill-opacity": 0.75,
      },
      interactive: true,
    },
    {
      id: "landuse_park",
      type: "fill",
      source: "mapbox",
      "source-layer": "landuse",
      filter: ["==", "class", "park"],
      paint: {
        "fill-color": "#d2edae",
      },
      interactive: true,
    },
    {
      id: "waterway",
      type: "line",
      source: "mapbox",
      "source-layer": "waterway",
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["in", "class", "river", "canal"],
      ],
      paint: {
        "line-color": "#a0cfdf",
        "line-width": {
          base: 1.4,
          stops: [
            [8, 0.5],
            [20, 15],
          ],
        },
      },
      interactive: true,
    },
    {
      id: "water",
      type: "fill",
      source: "mapbox",
      "source-layer": "water",
      paint: {
        "fill-color": "#a0cfdf",
      },
      interactive: true,
    },
    {
      id: "building",
      type: "fill",
      source: "mapbox",
      "source-layer": "building",
      paint: {
        "fill-color": "#d6d6d6",
      },
      interactive: true,
    },
    {
      interactive: true,
      layout: {
        "line-cap": "butt",
        "line-join": "miter",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway_link",
            "street",
            "street_limited",
            "service",
            "track",
            "pedestrian",
            "path",
            "link",
          ],
          ["==", "structure", "tunnel"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "tunnel_minor",
      paint: {
        "line-color": "#efefef",
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
        "line-dasharray": [0.36, 0.18],
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "butt",
        "line-join": "miter",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway",
            "primary",
            "secondary",
            "tertiary",
            "trunk",
          ],
          ["==", "structure", "tunnel"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "tunnel_major",
      paint: {
        "line-color": "#fff",
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
        "line-dasharray": [0.28, 0.14],
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway_link",
            "street",
            "street_limited",
            "service",
            "track",
            "pedestrian",
            "path",
            "link",
          ],
          ["in", "structure", "none", "ford"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "road_minor",
      paint: {
        "line-color": "#efefef",
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway",
            "primary",
            "secondary",
            "tertiary",
            "trunk",
          ],
          ["in", "structure", "none", "ford"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "road_major",
      paint: {
        "line-color": "#fff",
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "butt",
        "line-join": "miter",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway_link",
            "street",
            "street_limited",
            "service",
            "track",
            "pedestrian",
            "path",
            "link",
          ],
          ["==", "structure", "bridge"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "bridge_minor case",
      paint: {
        "line-color": "#dedede",
        "line-width": {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
        "line-gap-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "butt",
        "line-join": "miter",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway",
            "primary",
            "secondary",
            "tertiary",
            "trunk",
          ],
          ["==", "structure", "bridge"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "bridge_major case",
      paint: {
        "line-color": "#dedede",
        "line-width": {
          base: 1.6,
          stops: [
            [12, 0.5],
            [20, 10],
          ],
        },
        "line-gap-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway_link",
            "street",
            "street_limited",
            "service",
            "track",
            "pedestrian",
            "path",
            "link",
          ],
          ["==", "structure", "bridge"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "bridge_minor",
      paint: {
        "line-color": "#efefef",
        "line-width": {
          base: 1.55,
          stops: [
            [4, 0.25],
            [20, 30],
          ],
        },
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "all",
          [
            "in",
            "class",
            "motorway",
            "primary",
            "secondary",
            "tertiary",
            "trunk",
          ],
          ["==", "structure", "bridge"],
        ],
      ],
      type: "line",
      source: "mapbox",
      id: "bridge_major",
      paint: {
        "line-color": "#fff",
        "line-width": {
          base: 1.4,
          stops: [
            [6, 0.5],
            [20, 30],
          ],
        },
      },
      "source-layer": "road",
    },
    {
      interactive: true,
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["all", ["<=", "admin_level", 2], ["==", "maritime", 0]],
      ],
      type: "line",
      source: "mapbox",
      id: "admin_country",
      paint: {
        "line-color": "#8b8a8a",
        "line-width": {
          base: 1.3,
          stops: [
            [3, 0.5],
            [22, 15],
          ],
        },
      },
      "source-layer": "admin",
    },
    {
      interactive: true,
      minzoom: 5,
      layout: {
        "icon-image": "{maki}-11",
        "text-offset": [0, 0.5],
        "text-field": "{name_en}",
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-max-width": 8,
        "text-anchor": "top",
        "text-size": 11,
        "icon-size": 1,
      },
      filter: [
        "all",
        ["==", "$type", "Point"],
        ["all", ["==", "scalerank", 1], ["==", "localrank", 1]],
      ],
      type: "symbol",
      source: "mapbox",
      id: "poi_label",
      paint: {
        "text-color": "#666",
        "text-halo-width": 1,
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-blur": 1,
      },
      "source-layer": "poi_label",
    },
    {
      interactive: true,
      layout: {
        "symbol-placement": "line",
        "text-field": "{name_en}",
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-transform": "uppercase",
        "text-letter-spacing": 0.1,
        "text-size": {
          base: 1.4,
          stops: [
            [10, 8],
            [20, 14],
          ],
        },
      },
      filter: [
        "all",
        ["==", "$type", "LineString"],
        [
          "in",
          "class",
          "motorway",
          "primary",
          "secondary",
          "tertiary",
          "trunk",
        ],
      ],
      type: "symbol",
      source: "mapbox",
      id: "road_major_label",
      paint: {
        "text-color": "#666",
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 2,
      },
      "source-layer": "road_label",
    },
    {
      interactive: true,
      minzoom: 8,
      layout: {
        "text-field": "{name_en}",
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-max-width": 6,
        "text-size": {
          stops: [
            [6, 12],
            [12, 16],
          ],
        },
      },
      filter: [
        "all",
        ["==", "$type", "Point"],
        [
          "in",
          "type",
          "town",
          "village",
          "hamlet",
          "suburb",
          "neighbourhood",
          "island",
        ],
      ],
      type: "symbol",
      source: "mapbox",
      id: "place_label_other",
      paint: {
        "text-color": "#666",
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 1,
        "text-halo-blur": 1,
      },
      "source-layer": "place_label",
    },
    {
      interactive: true,
      layout: {
        "text-field": "{name_en}",
        "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
        "text-max-width": 10,
        "text-size": {
          stops: [
            [3, 12],
            [8, 16],
          ],
        },
      },
      maxzoom: 16,
      filter: ["all", ["==", "$type", "Point"], ["==", "type", "city"]],
      type: "symbol",
      source: "mapbox",
      id: "place_label_city",
      paint: {
        "text-color": "#666",
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 1,
        "text-halo-blur": 1,
      },
      "source-layer": "place_label",
    },
    {
      interactive: true,
      layout: {
        "text-field": "{name_en}",
        "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
        "text-max-width": 10,
        "text-size": {
          stops: [
            [3, 14],
            [8, 22],
          ],
        },
      },
      maxzoom: 12,
      filter: ["==", "$type", "Point"],
      type: "symbol",
      source: "mapbox",
      id: "country_label",
      paint: {
        "text-color": "#666",
        "text-halo-color": "rgba(255,255,255,0.75)",
        "text-halo-width": 1,
        "text-halo-blur": 1,
      },
      "source-layer": "country_label",
    },
  ],
};

// export const LANGUAGE_COUNTRY_CODE = [{
//   "English" : "GB",
//   "French" : "FR",
//   "Spanish" : "ES",
//   "Italian" : "IT",
//   "Arabic" : "SA",
// }]

export const LANGUAGE_COUNTRY_CODE = [
  {
    code: "GB",
    alias: ["English", "Inglés", "Anglais"],
  },
  {
    code: "FR",
    alias: ["French", "Francés", "Français"],
  },
  {
    code: "ES",
    alias: ["Spanish", "Español", "Espagnol"],
  },
  {
    code: "IT",
    alias: ["Italian", "Italiano", "Italien"],
  },
  {
    code: "SA",
    alias: ["Arabic", "Árabe", "Arabe"],
  },
];
