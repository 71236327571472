import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GeneralResponse } from "../../../types/common";
import {
  ITopExperiences,
  WwwbsTopExperiencesMainActivity,
} from "../../../types/topExperiences";
import { topExperiencesApi } from "../../api";
import { RootState } from "../store";

interface ITopExperiencesSlice {
  data: GeneralResponse<ITopExperiences[][]> | null;
  experience: ExperienceDetails;
}

type ExperienceDetails = {
  details: WwwbsTopExperiencesMainActivity[] | null;
  currentIndex: number;
};

let initialState: ITopExperiencesSlice = {
  data: null,
  experience: {
    details: null,
    currentIndex: -1,
  },
};

const topExperiencesSlice = createSlice({
  name: "topExperiences",
  initialState,
  reducers: {
    setData(
      state,
      action: PayloadAction<GeneralResponse<ITopExperiences[][]>>
    ) {
      state.data = action.payload;
    },
    resetData(state) {
      state.data = null;
    },
    setExperienceDetails(state, action: PayloadAction<ExperienceDetails>) {
      state.experience.details = action.payload.details;
      state.experience.currentIndex = action.payload.currentIndex;
    },
  },
});

export const { resetData, setData, setExperienceDetails } =
  topExperiencesSlice.actions;
export const getTopExperiences = (state: RootState) =>
  state.rootReducers.topExperiencesSlice.data;
export const getTopExperience = (state: RootState) =>
  state.rootReducers.topExperiencesSlice.experience;
export default topExperiencesSlice.reducer;
