import languageDetector from "@lib/languageDetector";

export const sortByOrderId = (arr: Array<any>) => {
  return arr.sort((a, b) => a.order_s - b.order_s);
};

export const languageCheck = (
  mainLang?: any,
  secondLang?: any,
  thirdLang?: any
) => {
  const detectedLang = languageDetector.detect();
  const choosedLang =
    detectedLang === "en"
      ? mainLang
      : detectedLang === "es"
      ? secondLang
      : detectedLang === "fr"
      ? thirdLang
      : mainLang;
  return choosedLang;
};

export const createMarkup = (c: any) => {
  return { __html: c.replace(/\\\/|\/\\/g, "/") };
};

export const usernameEdit = (username: string) => {
  return username?.length > 10
    ? username.split("").slice(0, 9).join("") + "..."
    : username;
};

export const minimizeText = (text: string, maxLetters = 10) => {
  return maxLetters < 99
    ? text?.length > maxLetters
      ? text.split("").slice(0, maxLetters).join("") + "..."
      : text
    : text;
};

export const getMobileDetect = (userAgent: NavigatorID["userAgent"]) => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i));
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = () => Boolean(userAgent.match(/SSR/i));
  const isSafari = () => Boolean(userAgent.match(/safari/i));
  const isMobile = () =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = () => Boolean(!isMobile() && !isSSR());
  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isSSR,
    isSafari,
  };
};

export const openInNewTab = (url: string | URL | undefined) => {
  window.open(url, "_blank", "noreferrer");
};

export const getCookie = (cookieName: string) => {
  let cookie: {
    [key: string]: any;
  } = {};
  document.cookie.split(";").forEach(function (el) {
    let [key, value] = el.split("=");
    cookie[key.trim()] = value;
  });
  return cookie[cookieName];
};
