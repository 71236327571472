import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GeneralResponse } from "../../types/common";
import {
  ICategories,
  IMusementActivityReviews,
  ISpotInfo,
  ISpotTopExperiences,
  ISpotTopMustSee,
} from "../../types/spotInfo";
import { RootState } from "../store/store";
import { config } from "../../shared";
import { ISpotToptHotels } from "../../types/spotTopHotels";
import { IMusementActivity } from "../../types/musement-activity";

type activityParams = {
  activityId?: number;
  lang?: string;
  section?: string;
};

type MusementActivityReviewsParams = {
  activity_id: string;
  section: string;
};

export const spotInfoApi = createApi({
  reducerPath: "spotInfo",
  baseQuery: fetchBaseQuery({
    baseUrl: config?.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      // If we have a token set in state, let's assume that we should be passing it.
      if (state.rootReducers.authSlice.token) {
        headers.set(
          "authorization",
          `Bearer ${state.rootReducers.authSlice.token}`
        );
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getSpotInfo: builder.query<GeneralResponse<ISpotInfo[][]>, string>({
      query: (city_id) => `/spotProfile/getSpotInfo?city_id=${city_id}`,
    }),
    getSpotTopExperiences: builder.query<
      GeneralResponse<ISpotTopExperiences[][]>,
      string
    >({
      query: (city_id) =>
        `/spotProfile/getSpotTopExperiences?city_id=${city_id}`,
      transformResponse: (
        response: GeneralResponse<ISpotTopExperiences[][]>
      ): any => {
        if (response.data.length === 0)
          return {
            statusCode: response.statusCode,
            message: response.message,
            data: [],
          };
        return {
          statusCode: response.statusCode,
          message: response.message,
          data: response.data,
        };
      },
    }),
    getSpotTopMustSee: builder.query<
      GeneralResponse<ISpotTopMustSee[][]>,
      string
    >({
      query: (city_id) => `/spotProfile/getSpotTopMustSee?city_id=${city_id}`,
    }),
    getSpotTopHotels: builder.query<
      GeneralResponse<ISpotToptHotels[][]>,
      string
    >({
      query: (city_id) => `/spotProfile/getSpotTopHotels?city_id=${city_id}`,
    }),
    getCategories: builder.query<GeneralResponse<ICategories[][]>, unknown>({
      query: () => `/spotProfile/getSpotCategoriesFilter`,
    }),
    getMusementActivity: builder.query<
      GeneralResponse<IMusementActivity>,
      activityParams
    >({
      query: (activityParams) =>
        `/spotProfile/getMusementActivity?activity_id=${
          activityParams.activityId
        }&lang=${activityParams.lang}${
          activityParams.section ? `&section=${activityParams.section}` : ""
        }`,
    }),
    getMusementActivityReviews: builder.query<
      GeneralResponse<IMusementActivityReviews>,
      MusementActivityReviewsParams
    >({
      query: (activityParams) =>
        `/spotProfile/getMusementActivityReviews?activity_id=${activityParams.activity_id}&section=${activityParams.section}`,
    }),
  }),
});

export const {
  useGetSpotInfoQuery,
  useGetSpotTopExperiencesQuery,
  useGetSpotTopMustSeeQuery,
  useGetSpotTopHotelsQuery,
  useGetCategoriesQuery,
  useGetMusementActivityQuery,
  useGetMusementActivityReviewsQuery
} = spotInfoApi;
