import { combineReducers } from 'redux'

import count from './count/countSlice'
import authSlice from './auth/authSlice';
import topExperiencesSlice from './topExperiences/topExperiencesSlice';

const reducers = combineReducers({ 
  count,
  authSlice,
  topExperiencesSlice
})

export default reducers;