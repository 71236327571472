import { useMobileDetect } from "@hooks/useMobileDetect";

export const Divider = () => {
  const { isMobile } = useMobileDetect();
  return isMobile() ? (
    <svg
      className="absolute bottom-0 w-screen"
      viewBox="0 0 154 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_4)">
        <path
          d="M154.107 141.516V22.7437C154.107 22.7437 124.952 39.8604 74.3365 39.8604C23.7207 39.8604 -0.641663 22.7437 -0.641663 22.7437V141.516H154.107Z"
          fill="url(#paint0_linear_1_4)"
        />
        <path
          d="M154 109.675V0C154 0 125.951 23.9681 75.5793 23.9681C25.2084 23.9681 0 0 0 0V109.675H154Z"
          fill="url(#paint1_linear_1_4)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_4"
          x1="76.7326"
          y1="22.7437"
          x2="76.7326"
          y2="111.415"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#090909" stopOpacity="0" />
          <stop offset="0.296875" stopColor="#090909" stopOpacity="0.37013" />
          <stop offset="0.802083" stopColor="#090909" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_4"
          x1="77"
          y1="-29.0316"
          x2="77"
          y2="74.5216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#090909" stopOpacity="0" />
          <stop offset="0.296875" stopColor="#090909" stopOpacity="0.37013" />
          <stop offset="0.802083" stopColor="#090909" />
        </linearGradient>
        <clipPath id="clip0_1_4">
          <rect width="154" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      className="absolute bottom-0 w-screen"
      viewBox="0 0 1440 277"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1441 280V52.1047C1441 52.1047 1190.79 151.84 717.5 151.84C244.21 151.84 -6 45 -6 45V280H1441Z"
        fill="url(#paint0_linear_1170_4417)"
      />
      <path
        d="M1441 215V52.5C1441 52.5 1183.76 140.5 710.466 140.5C237.176 140.5 -6 45 -6 45V215H1441Z"
        fill="url(#paint1_linear_1170_4417)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1170_4417"
          x1="717.5"
          y1="45"
          x2="717.5"
          y2="220.442"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#090909" stopOpacity="0" />
          <stop offset="0.296875" stopColor="#090909" stopOpacity="0.37013" />
          <stop offset="0.802083" stopColor="#090909" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1170_4417"
          x1="717.5"
          y1="2.81388e-06"
          x2="717.5"
          y2="160.511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#090909" stopOpacity="0" />
          <stop offset="0.296875" stopColor="#090909" stopOpacity="0.37013" />
          <stop offset="0.802083" stopColor="#090909" />
        </linearGradient>
      </defs>
    </svg>
  );
};
