import React from "react";

interface CustomHeadlineProps {
  normalPart: string;
  coloredPart: string;
  size?: string;
  style?: string;
}

export const CustomHeadline = ({
  normalPart,
  coloredPart,
  size,
  style
}: CustomHeadlineProps) => {
  return (
    <div className={`flex items-center justify-start mb-1 text-center ${style}`}>
      <h2
        className={`font-bold tracking-wider text-white ${
          size ? size : "text-2xl"
        }`}
      >
        {normalPart} <span className="text-primary-color">{coloredPart}</span>
      </h2>
    </div>
  );
};
